import React from "react"
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import SwirlContainer from '../container-fluid-swirl'
import Layout from '../layout'

const build_head_from = (pageContext, siteMetadata) => {
  const { baseUri, title } = siteMetadata

  const head = {
    title: `Catgeory: ${pageContext.label} - ${title}`,

    link: [
      { rel: "canonical", href: baseUri + pageContext.url },
    ],
  }

  return head
}

export default ({
  data,
  pageContext,
}) => {
  const { site } = data
  const { label, posts } = pageContext

  return <Layout>
    <Helmet {...build_head_from(pageContext, site.siteMetadata)}
    />

    <SwirlContainer>
      &nbsp;
    </SwirlContainer>

    <section className="container">
      <article className="post-content">
        <header className="pb-2 my-4 border-bottom">
          <h1>Category: {label}</h1>
        </header>

        <ul className="list-unstyled row">
          { posts.map(post =>
            <li key={post.url} className="col-12 col-md-6 col-lg-4">
              <Link to={post.url}>
                {post.title}
              </Link>
              <small className="text-muted float-right">{post.date}</small>
            </li>
          ) }
        </ul>
      </article>
    </section>
  </Layout>
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        baseUri
      }
    }
  }
`
